import '../scss/style.scss';
import { Collapse, Modal } from 'flowbite';
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

document.addEventListener('DOMContentLoaded', function () {

    const $targetEl = document.getElementById('targetEl');

    const $triggerEl = document.getElementById('triggerEl');

    const options = {
        onCollapse: () => {
            console.log('element has been collapsed');
        },
        onExpand: () => {
            console.log('element has been expanded');
        },
        onToggle: () => {
            console.log('element has been toggled');
        },
    };

    const instanceOptions = {
        id: 'targetEl',
        override: true
    };

    const instanceOptions2 = {
        id: 'modalEl',
        override: true
    };

    const titles = document.querySelectorAll('.accordion .title');

    titles.forEach(title => {
        title.addEventListener('click', function() {
            const description = this.nextElementSibling;
            const isOpen = !description.classList.contains('hidden');

            document.querySelectorAll('.accordion .description').forEach(desc => {
                desc.classList.add('hidden');
                desc.classList.remove('block');
            });

            document.querySelectorAll('.accordion .title i').forEach(icon => {
                icon.classList.remove('fa-minus');
                icon.classList.add('fa-plus');
            });

            if (!isOpen) {
                description.classList.remove('hidden');
                description.classList.add('block');
                this.querySelector('i').classList.remove('fa-plus');
                this.querySelector('i').classList.add('fa-minus');
            }
        });
    });

    if(document.querySelector('.library-content')) {
        let sliders = document.querySelectorAll('[class*="document-slider-"]');

        sliders.forEach((slider, index) => {
            const swiper = new Swiper(slider, {
                modules: [Navigation, Pagination],
                loop: false,
                spaceBetween: 32,
                navigation: {
                    nextEl: `.swiper-button-next-${index + 1}`,
                },
                breakpoints: {
                    '0': {
                        slidesPerView: 1.2
                    },
                    '680': {
                        slidesPerView: 1.7
                    },
                    '1000': {
                        slidesPerView: 2.7,
                    }
                },
            });
        });
    }

    if(document.querySelector('.filter-trigger')) {
        document.querySelector('.filter-trigger').addEventListener('click', function(event) {
            event.preventDefault();

            let selects = document.querySelectorAll('select');
            let selectedValues = [];

            selects.forEach(function(select) {
                let value = select.value;
                if (value) {
                    selectedValues.push(value);
                }
            });

            if (selectedValues.length > 0) {
                let queryString = selectedValues.map(id => 'cat[]=' + id).join('&');
                let url = window.location.origin + window.location.pathname + '?' + queryString;
                window.location.href = url + '#filter-results';
            } else {
                const msg = document.querySelector('.warning-message');
                msg.classList.add('active');
                setTimeout(() => {
                    msg.classList.remove('active');
                }, 3000);
            }
        });
    }

    if(document.getElementById('filter-results')) {
        const urlParams = new URLSearchParams(window.location.search);
        const hasCat = [...urlParams.keys()].some(key => key.startsWith('cat['));
        if (hasCat) {
            const scrollTo = document.getElementById('filter-results');
            if (scrollTo) {
                scrollTo.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }
});